import { createRouter, createWebHashHistory } from 'vue-router';

export const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { notoken: true },
    component: () => import('@/data/login/login.vue'),
  },
  {
    path: '/password',
    name: 'password',
    meta: { notoken: true },
    notoken: true,
    component: () => import('@/data/login/password.vue'),
  },
  {
    path: '/',
    title: '数据中心',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/data/index/index.vue'),
        meta: { titL: ['数据统计'], tab: '/index' },
      },
    ],
  },
  {
    path: '/',
    title: '活动',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/activitytype',
        name: 'activitytype',
        component: () => import('@/data/activity/activitytype.vue'),
        meta: { titL: ['活动类型'], tab: '/activitytype' },
      },
      {
        path: '/activitytypeEdit/:id',
        name: 'activitytypeEdit',
        component: () => import('@/data/activity/activitytypeEdit.vue'),
        meta: { titL: ['活动类型', '设置活动模版'], tab: '/activitytype' },
      },
      {
        path: '/activitylist',
        name: 'activitylist',
        component: () => import('@/data/activity/activitylist.vue'),
        meta: { titL: ['活动列表'], tab: '/activitylist' },
      },
      {
        path: '/activityAdd',
        name: 'activityAdd',
        component: () => import('@/data/activity/activityAdd.vue'),
        meta: { titL: ['活动列表', '添加活动'], tab: '/activitylist' },
      },
      {
        path: '/activitydetail/:id',
        name: 'activitydetail',
        component: () => import('@/data/activity/activitydetail.vue'),
        meta: { titL: ['活动详情'], tab: '/activitylist' },
      },
      {
        path: '/activitydetails/:id',
        name: 'activitydetails',
        component: () => import('@/data/activity/activitydetails.vue'),
        meta: { titL: ['学员评教汇总'], tab: '/activitylist' },
      },
    ],
  },
  {
    path: '/',
    title: '评价',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/evaluationTemplateL',
        name: 'evaluationTemplateL',
        component: () => import('@/data/evaluation/evaluationTemplateL.vue'),
        meta: { titL: ['评价模版'], tab: '/evaluationTemplateL' },
      },
      {
        path: '/evaluationTemplateAdd/:id',
        name: 'evaluationTemplateAdd',
        component: () => import('@/data/evaluation/evaluationTemplateAdd.vue'),
        meta: { titL: ['评价模版', '添加/编辑模版'], tab: '/evaluationTemplateL' },
      },
      {
        path: '/evaluationParametersL',
        name: 'evaluationParametersL',
        component: () => import('@/data/evaluation/evaluationParametersL.vue'),
        meta: { titL: ['评价参数库'], tab: '/evaluationParametersL' },
      },
    ],
  },
  {
    path: '/',
    title: '人员管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/studentL',
        name: 'studentL',
        component: () => import('@/data/user/studentL.vue'),
        meta: { titL: ['学员管理'], tab: '/studentL' },
      },
      {
        path: '/teacherL',
        name: 'teacherL',
        component: () => import('@/data/user/teacherL.vue'),
        meta: { titL: ['教师管理'], tab: '/teacherL' },
      },
      {
        path: '/supervisionL',
        name: 'supervisionL',
        component: () => import('@/data/user/supervisionL.vue'),
        meta: { titL: ['督导管理'], tab: '/supervisionL' },
      },
    ],
  },
  {
    path: '/',
    title: '学生轮转',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/rotateL',
        name: 'rotateL',
        component: () => import('@/data/rotate/list.vue'),
        meta: { titL: ['学生轮转'], tab: '/rotateL' },
      },
    ],
  },
  {
    path: '/',
    title: '基地管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/baseL',
        name: 'baseL',
        component: () => import('@/data/base/index.vue'),
        meta: { titL: ['基地管理'], tab: '/baseL' },
      }
    ],
  },
  {
    path: '/',
    title: '科室管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/departmentL',
        name: 'departmentL',
        component: () => import('@/data/department/index.vue'),
        meta: { titL: ['科室管理'], tab: '/departmentL' },
      },
      {
        path: '/departmentInfo/:data',
        name: 'departmentInfo',
        component: () => import('@/data/department/info.vue'),
        meta: { titL: ['科室管理','科室详情'], tab: '/departmentL' },
      },
    ],
  },

  {
    path: '/',
    title: '设置',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/operationLog',
        name: 'operationLog',
        component: () => import('@/data/set/operationLog.vue'),
        meta: { titL: ['操作日志'], tab: '/operationLog' },
      },
      {
        path: '/advertisement',
        name: 'advertisement',
        component: () => import('@/data/set/advertisement.vue'),
        meta: { titL: ['Banner设置'], tab: '/advertisement' },
      },

      {
        path: '/identity',
        name: 'identity',
        component: () => import('@/data/set/identity.vue'),
        meta: { titL: ['身份管理'], tab: '/identity', },
      },
      {
        path: '/manager',
        name: 'manager',
        component: () => import('@/data/set/manager.vue'),
        meta: { titL: ['管理员权限设置'], tab: '/manager', },
      },
      {
        path: '/article',
        name: 'article',
        component: () => import('@/data/set/article.vue'),
        meta: { titL: ['文章管理'], tab: '/article', keepAlive: true },
      },
      {
        path: '/articleEdit/:type',
        name: 'articleEdit',
        component: () => import('@/data/set/articleEdit.vue'),
        meta: { titL: ['编辑文章'], tab: '/article' },
      },
      // {
      //   path: '/revisepassword',
      //   name: 'revisepassword',
      //   component: () => import('@/data/revisepassword/index.vue'),
      //   meta: { titL: ['修改密码'], tab: '/revisepassword', keepAlive: true },
      // },
    ],
  },
  {
    path: '/',
    title: '消息',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/systemNews',
        name: 'systemNews',
        component: () => import('@/data/news/systemNews.vue'),
        meta: { titL: ['系统消息'], tab: '/systemNews'},
      },
      {
        path: '/systemNewsInfo',
        name: 'systemNewsInfo',
        component: () => import('@/data/news/systemNewsInfo.vue'),
        meta: { titL: ['系统消息', '详情'], tab: '/systemNews'},
      },
      {
        path: '/eCommerceNews',
        name: 'eCommerceNews',
        component: () => import('@/data/news/eCommerceNews.vue'),
        meta: {titL: ['推送消息设置'],tab: '/eCommerceNews'},
      },
      {
        path: '/issue',
        name: 'issue',
        component: () => import('@/data/news/issue.vue'),
        meta: { titL: ['发布消息'], tab: '/systemNews'},
      }
    ],
  },





];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
